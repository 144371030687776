<template>
  <b-overlay :show="shownOverlay" rounded="sm" opacity="1" variant="primary">
    <div class="ticket--column--list" @scroll="onScroll">
      <card @ticketId="ticketId" :cardData="element" :colorArray="colorArray"  v-for="(element,index) in listTicket" :key="index" ></card>
    </div>
  </b-overlay>
</template>
<script>
import card from '@/views/modules/tickets/components/List/Card.vue'
export default {
  props: ['listTicket', 'shownOverlay', 'colorArray'],
  components: {card},
  data() {
    return {
      sendEmit: false
    }
  },
  watch: {
    listTicket(newValue, oldValue) {
      const newValueLength = newValue ? newValue.length : 0
      const oldValueLength = oldValue ? oldValue.length : 0
      if (newValueLength !== oldValueLength) this.sendEmit = false
    }
  },
  methods: {
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if ((scrollTop + clientHeight >= scrollHeight - 1) && !this.sendEmit) {
        this.sendEmit = true
        this.$emit('update:shownOverlay', true)
      }
    },
    ticketId(event) {
      this.$emit('ticketId', event)
    }
  }
}
</script>
<style lang="scss" slot-scope>
  .ticket--column--list {
    height: calc(100vh - 250px);
    overflow-y: scroll;
    display: grid;
    gap: 10px;
    align-content: start;
  }
</style>