<template>
  <div class="card--container--ticket">    
    <div class="dropdown--relative">
      <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none p-0"  no-caret class="dropdown--container">
        <template #button-content>
          <b-icon :style="{color: `${cardColor}`}" icon="circle-fill"></b-icon>
        </template>
        <b-dropdown-item v-for="(color, index) in colorOptions" :key="index" link-class="dropdown-link-class" @click="selectColor(color)">
          <div class="link--container">
            <b-icon :style="{color: `${color.id}`}" icon="circle-fill"></b-icon>
            <p class="p-0 options--dropdown-text m-0">{{color.text}}</p>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  <b-button class="ticket--card" variant="link" @click="goToTicket" :style="{borderColor: `${cardData.borderColor}`}">
    <!-- <div class="days--answer" v-if="!!cardData.last_message && !!cardData.last_message.resolution_date">
      <p class="m-0 days--answer--text">Plazo de respuestas <br> <span class="days--answer--day">{{cardData.last_message.resolution_date}}</span></p>
    </div> -->
    <div class="ticket--card--head">
      <h1 class="h5 m-0"><p class="text--title">{{cardData.current_status.client_name}}</p></h1>
      <!-- <h2 class="h6 m-0"><span class="text-warning text--subtitle">{{cardData.current_status.sub_status_name}}</span></h2> -->
    </div>
    <div class="ticket--card--body">
      <!-- <p class="m-0" v-if="!!cardData.last_message && !!cardData.last_message.date"><feather-icon class="mr-03" icon="MailIcon"/><span> {{formatDate(cardData.last_message.date)}} </span></p> -->
      <p class="m-0">
        <!-- <feather-icon class="mr-03" icon="SendIcon"/> -->
        <span>OT {{!!cardData.delivery && !!cardData.delivery.tracking_number ? cardData.delivery.tracking_number : ''}} / Ref. {{!!cardData.delivery && !!cardData.delivery.imported_id ? cardData.delivery.imported_id : ''}}</span></p>
    </div>
  </b-button>
  </div>
</template>
<script>
import { environment } from '@/environments/environment'
import {
  BIcon,
  BIconCircleFill
} from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
export default {
  props: ['cardData', 'colorArray'],
  components: {BIcon, BIconCircleFill},
  data() {
    return {
      cardColor: 'blue'
    }
  },
  computed: {
    colorOptions() {
      return this.colorArray && this.colorArray.length > 0 ? this.colorArray : []
    }
  },
  methods: {
    goToTicket() {
      this.$bvModal.show('ModalTicketID')
      this.$emit('ticketId', this.cardData.delivery.id)
    },
    getImage(code) {
      return `url('https://storage.googleapis.com/enviame-misc/carriers/logos/color/${code.toLowerCase()}.png')`
    },
    formatDate(date) {
      return this.$options.filters.dbDateToFormat(date, 'dd-LL-y T')
    },
    selectColor(color) {
      console.log(color, this.cardData)
    }
  }
}
</script>
<style lang="scss" slot-scope>
  .ticket--card {
    width: 100%;
    height: fit-content;
    background: #FFF;;
    border-radius: 10px;
    border: 1px solid #044389;
    border-left: 15px solid #C7224A;
    padding: 10px;
    text-align: left;
    box-shadow: none;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px 0px #00000033;
    box-shadow: 0px 6px 30px 0px #0000001F;
    box-shadow: 0px 16px 24px 0px #00000024;


    .ticket--card--head {
      text-transform: capitalize;
      border-bottom: #E48E3F solid 1px;
      max-width: 80%;
    }
    .ticket--card--body {
      color: #5E5873;
      margin: 8px auto auto;
    }
    .ticket--card--body p {
      font-size: 12px;
      line-height: normal;
    }
    .text--subtitle {
      font-size: 10px;
      margin-left: 10px;
    }
    .text--title {
      font-weight: 700;
      color: #E48E3F;
      font-size: 12px;
      text-transform: lowercase;
      margin: 0;
      line-height: normal;
    }
    p.text--title::first-letter {
      text-transform: uppercase;
    }
    .days--answer {
      position: relative;
      top: -20px;
      right: -20px;
      float: right;
      background-color: #E48E3F;
      padding: 5px 10px;
      border-radius: 0 25px 0 30px;

      &--text {
        color: white;
        font-size: 8px;
        line-height: 1.1;
        text-align: right;
      }
      &--day {
        font-size: 12px;
      }
    }
  }
  .card--container--ticket {
    .dropdown--relative {
      position: relative;
    }
    .dropdown--container {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .options--dropdown-text {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 300;
      line-height: 14.63px;
      text-align: left;
      color: #5C5C5C;
    }
    .dropdown-link-class {
      padding: 10px;
    }
    .link--container {
      display: flex;
      gap: 10px;
    }
  }
</style>