<template>
  <section>
    <div>
      <b-button id="popover-reactive-1" variant="outline-primary" ref="button">
        <icons :icon="'Eyedropper'"></icons>
      </b-button>
    </div>
    <b-popover
      target="popover-reactive-1"
      triggers="click"
      :show.sync="popoverShow"
      placement="bottomleft"
      container="my-container"
      @show="onShow"
      ref="popover"
      custom-class="popover--container"
    >
      <template #title>
        Personaliza el seguimiento de tus tickets asignándoles etiquetas de color
        <b-button @click="onClose" aria-label="Close" variant="link" class="button-close">
          <feather-icon size="1.5x" icon="XIcon"/>
        </b-button>
      </template>
      <section>
        <p>
          Elige entre cinco colores predefinidos que puedes nombrar según tus necesidades. Utiliza estas etiquetas en las tarjetas para mantener un seguimiento detallado de cada incidencia en sus distintas etapas. Recuerda que estas etiquetas no afectan la priorización de tus tickets.
        </p>
        <b-form @submit="saveColors">
          <div v-for="(color, index) in colorArray" :key="index" class="item--form-container">
            <b-icon :style="{color: `${color.id}`}" icon="circle-fill" font-scale="2"></b-icon>
            <b-form-input
              v-model="form[color.id]"
              type="text"
              placeholder="Ingrese nombre de etiqueta"
            ></b-form-input>
          </div>
          <div class="d-flex justify-content-end mt-2 mb-2">
            <b-button type="submit" size="sm" variant="warning">Guardar</b-button>
          </div>
        </b-form>
      </section>
    </b-popover>
  </section>
</template>

<script>
import Icons from '@/Icons/Icons.vue'
import {
  BIcon,
  BIconEyeglasses,
  BIconCircleFill
} from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
export default {
  props: ['colorArray'],
  components: {BIcon, BIconEyeglasses, BIconCircleFill, Icons},
  data() {
    return {
      popoverShow: false,
      form: {}
    }
  },
  methods: {
    onClose() {
      this.popoverShow = false
    },
    saveColors(e) {
      e.preventDefault()
      console.log('ok', this.form)
    },
    onShow() {
      this.form = this.colorArray.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.text }), {})
    }
  }
}
</script>
<style lang="scss">
  .popover--container {
    min-width: 750px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 300;
    line-height: 14.63px;
    text-align: left;

    .popover-header {
      background: white;
      color: #044389;
      font-size: 15px;
      font-weight: 500;
      line-height: 18.29px;
      border: none;
      text-decoration: underline;
    }
    .button-close {
      height: auto;
      padding: 0;
      float: right;
    }
    .item--form-container {
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 5px 0;
    }
  }
</style>